import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import Logo from "../assets/images/logo.svg"

class Apply extends React.Component {
  render() {
    return (
      <>
        <div className="apply_logo">
          <a href="/">
            <img src={Logo} alt="hookpoint" />
          </a>
        </div>
        <SEO title="Apply" />
        <div className="apply_holder">
          <h1>Are you interested in working with Hook Point?</h1>
          <p>
            Let's connect! Will you please schedule a time below to speak with
            one of our team members?
          </p>
          <iframe
            width="100%"
            height="620"
            src="https://3web.bg/tmp/apply.html"
            title="hookpoint-apply"
          />
        </div>
      </>
    )
  }
}

export default Apply
